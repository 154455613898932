import {captureException} from "@sentry/react"
import {useRouteError} from "react-router-dom"
import {useOnce} from "./react/hooks"
import {ErrorScreen} from "./screens/ErrorScreen"

// Unlike Sentry's ErrorBoundary, this won't capture the react component tree - see
// https://github.com/getsentry/sentry-javascript/blob/b9b4cb25967f48db44f89969d8e06b121a581ce0/packages/react/src/errorboundary.tsx#L132-L139
// and https://github.com/getsentry/sentry-javascript/discussions/6912.
// Because of that, we wrap the root layout with Sentry's ErrorBoundary, so this will only be
// used for react-router's routing errors (e.g., route not found).
export function RouterError() {
  const error = useRouteError() as any

  useOnce(() => {
    // The outer object usually just contains "error" and "data" (which is just error.message)
    captureException(error?.error ?? error)
  })

  return <ErrorScreen />
}
