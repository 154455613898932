import {nanoid} from "nanoid"
import {WebSocket} from "partysocket"
import SuperJSON from "superjson"
import {getOrSetItem, mustStringProp} from "../shared/utils/builtins"
import {mustFetchJson} from "../shared/utils/fetch"
import {Atom, atom} from "../xignal/atom"
import {UNKNOWN_KEYS, batch} from "../xignal/batch"
import {Watcher, computed} from "../xignal/computed"
import {ItemAtom} from "../xignal/itemAtom"
import {SetAtom} from "../xignal/setAtom"
import {Live, LiveAtom, liveAtom, liveSetAtom} from "./liveAtom"
import {LiveEvent, eventsByName} from "./liveEvent"
import {BaseMsg, Msg, createMsg, msgSchema, optimizeMsg} from "./shared/msg"
import {initHeartbeat} from "./shared/socketHeartbeat"

export type PlayerId = string & {} // `& {}` keeps alias name in intellisense
export const ANY_PLAYER_LEADER = Symbol("ANY_PLAYER_LEADER")
export const NO_LEADER = Symbol("NO_LEADER")
export type Leader = PlayerId | typeof ANY_PLAYER_LEADER | typeof NO_LEADER

type UpdateMode = "local" | "pessimistic" | "optimistic"

export class LiveContext {
  #ws: WebSocket | undefined
  #roomCode: string | undefined
  #shouldResetAtoms = false
  #serverUrl = "https://livestate.qed9.com"
  #pingInterval = 5000
  #pendingUpdates: (Msg & Required<BaseMsg>) | undefined

  // NB: We no longer need this global view of all atoms that have unconfirmed data.
  // We could move this to a field directly on each LiveAtom
  /** @internal */
  _unconfirmedVersions = new Map<LiveAtom, Map<string | UNKNOWN_KEYS, number>>()
  /** @internal */
  _liveAtoms = new Map<string, LiveAtom>()
  /** @internal */
  _liveEvents = new Map<string, LiveEvent>()
  /** @internal */
  _updateMode: UpdateMode = "optimistic"
  /** @internal */
  _stateVersion = 0

  // These rely on stuff above being initialized (via `this` being passed as arg)
  readonly $hostId = liveAtom("hostId", "UNINITIALIZED_HOST", this)
  readonly $isHost = computed(($) => $(this.$hostId) === myId)
  readonly $leader = atom<Leader>(NO_LEADER)
  readonly $playerIds = liveSetAtom<PlayerId>("playerIds", undefined, this) as Live<
    SetAtom<PlayerId>
  > // readonly

  get roomCode() {
    return this.#roomCode
  }

  setServerUrl(url: string) {
    if (this.#roomCode) {
      throw new Error("setServerUrl can only be called when disconnected from a room")
    }
    this.#serverUrl = url
  }

  pessimisticBatch = <T>(callback: () => T): T => {
    return this.#batchWithMode("pessimistic", callback)
  }

  #batchWithMode<T>(updateMode: UpdateMode, callback: () => T): T {
    return batch(() => {
      const oldMode = this._updateMode
      this._updateMode = updateMode
      try {
        return callback()
      } finally {
        // We reset this before the end of the batch callback; so it won't apply to event listeners
        this._updateMode = oldMode
      }
    })
  }

  #sendUpdates() {
    if (!this.#pendingUpdates) return
    optimizeMsg(this.#pendingUpdates)
    this.#ws!.send(SuperJSON.stringify(this.#pendingUpdates))
    this.#pendingUpdates = undefined
  }

  /** @internal */
  _scheduleUpdate() {
    if (!this.#ws) throw new Error("You must be connected to a room before setting live state")
    if (!this.#pendingUpdates) {
      this.#pendingUpdates = createMsg(++this._stateVersion)
      void Promise.resolve().then(() => {
        this.#sendUpdates()
      })
    }
    return this.#pendingUpdates
  }

  leaveRoom() {
    if (!this.#ws) return
    console.log("Leaving room", this.#roomCode)

    this.#sendUpdates()
    this.#ws.close()
    this.#ws = undefined

    // NB: the onclose handler also sets a flag to call resetAtoms when the next connection is
    // opened, but we do immediately here when explicitly leaving the room.
    this.#batchWithMode("local", () => this.resetAtoms())
    this.#roomCode = undefined
  }

  resetAtoms() {
    batch(() => {
      for (const $atom of this._liveAtoms.values()) $atom.reset()
    })
  }

  async createRoom(): Promise<string> {
    const res = (await mustFetchJson(this.#serverUrl + `/room`, {method: "POST"})) as any
    const roomCode = mustStringProp(res, "roomCode")
    return roomCode
  }

  joinRoom(roomCode: string): Promise<void> {
    if (this.#roomCode) throw new Error("Already joined/joining a room. Call leaveRoom first.")
    console.log("Joining room", roomCode)
    this.#roomCode = roomCode

    // Resolves/rejects after first message/error/close
    return new Promise<void>((resolve, reject) => {
      const urlParams = new URLSearchParams({
        playerId: myId,
        pingMs: String(this.#pingInterval),
      })
      this.#ws = new WebSocket(this.#serverUrl + `/join/${roomCode}?${urlParams}`)
      initHeartbeat(this.#ws as any, this.#pingInterval, () => {
        // https://github.com/partykit/partykit/blob/9cb3fe9bd99a821b3ccd687c49fb6ff0aa119b98/packages/partysocket/src/ws.ts#L514
        ;(this.#ws as any)._disconnect()
      })
      this.#ws.onmessage = (event) => {
        if (event.data === "BADROOM") {
          // The server will also close the connection, but this prevents PartySocket from
          // continuously retrying the connection.
          this.#ws!.close()
          reject("Invalid room code: " + roomCode)
          return
        }
        const msg = msgSchema.parse(SuperJSON.parse(event.data))
        this.#batchWithMode("local", () => {
          if (this.#shouldResetAtoms) {
            this.#shouldResetAtoms = false
            this.resetAtoms()
          }
          this.#processMsg(msg)
        })
        resolve()
      }
      this.#ws.onclose = (event) => {
        this.#shouldResetAtoms = true

        let errorExtra = ""
        if (!event.wasClean) {
          errorExtra = ` Code: ${event.code}. Reason: ${event.reason}`
          console.error("WebSocket closed with error." + errorExtra)
        }
        reject("Room closed before join completed" + errorExtra)
      }
      this.#ws.onerror = () => {
        reject("WebSocket error")
      }
    })
  }

  // NB: This could likely be simplified and not have to be an awkward abstraction used in two
  // different places if we changed the way we handle setting ItemAtoms with UNKNOWN_KEYS. If we
  // convert those to setting all known keys in the client to REMOVE_ITEM, then we'd never have an
  // UNKNOWN_KEYS unconfirmedVersion for ItemAtoms, and we could get rid of a lot of special cases.
  #processUnconfirmedVersions(
    $atom: LiveAtom,
    items: Record<string, unknown>,
    version: undefined | number,
    cb: (key: string, itemsCopy: Record<string, unknown>) => void,
  ) {
    const unconfirmedVersion = this._unconfirmedVersions.get($atom)
    if (!unconfirmedVersion) return items

    // We don't overwrite our own unconfirmed updates -- we know it will be acked soon and don't
    // want the value to jump back and forth.
    try {
      const unknownKeysVersion = unconfirmedVersion.get(UNKNOWN_KEYS)
      if (unknownKeysVersion != null) {
        if (version == null || version < unknownKeysVersion) return UNKNOWN_KEYS
        if (version > unknownKeysVersion) {
          console.error("Ack version > unknownKeysVersion (previous ack wasn't processed)", {
            version,
            unknownKeysVersion,
          })
        }
        unconfirmedVersion.delete(UNKNOWN_KEYS)
      }

      let itemsCopy: Record<string, unknown> | undefined
      for (const [unconfirmedKey, keyVersion] of unconfirmedVersion) {
        if (version != null && version >= keyVersion) {
          if (version > keyVersion) {
            console.error("Ack version > keyVersion (previous ack wasn't processed)", {
              key: unconfirmedKey,
              version,
              keyVersion,
            })
          }
          unconfirmedVersion.delete(unconfirmedKey)
        } else {
          itemsCopy ??= Object.assign({}, items)
          cb(unconfirmedKey as string, itemsCopy)
        }
      }
      return itemsCopy ?? items
    } finally {
      if (unconfirmedVersion.size === 0) {
        this._unconfirmedVersions.delete($atom)
      }
    }
  }

  #processMsg(msg: Msg) {
    const {s: sets, i: setItems, e: events, v: version} = msg
    if (events) {
      for (const [name, ...args] of events) {
        const event = eventsByName.get(name)
        if (!event) throw Error(`Got unknown event "${name}"`)
        event._emit(...args)
      }
    }
    if (sets) {
      for (const [liveKey, val] of Object.entries(sets)) {
        const $atom = this._liveAtoms.get(liveKey)
        if (!$atom) {
          console.error(`No atom for liveKey: ${liveKey}`)
          continue
        }

        const unconfirmedVal = this.#processUnconfirmedVersions(
          $atom,
          val as any,
          version,
          (unconfirmedKey, itemsCopy) => {
            itemsCopy[unconfirmedKey] = ($atom as any as ItemAtom).getItem(unconfirmedKey)
          },
        )
        if ("setToItems" in $atom) {
          if (unconfirmedVal !== UNKNOWN_KEYS)
            ($atom as any as ItemAtom).setToItems(Object.entries(unconfirmedVal as object))
          ;($atom.$confirmed as any as ItemAtom).setToItems(Object.entries(val as object))
        } else {
          if (unconfirmedVal !== UNKNOWN_KEYS) $atom.set(unconfirmedVal)
          $atom.$confirmed.set(val)
        }
      }
    }
    if (setItems) {
      for (let [liveKey, items] of Object.entries(setItems)) {
        const $atom = this._liveAtoms.get(liveKey)
        if (!$atom) {
          console.error(`No atom for liveKey: ${liveKey}`)
          continue
        }

        const unconfirmedItems = this.#processUnconfirmedVersions(
          $atom,
          items,
          version,
          (unconfirmedKey, itemsCopy) => {
            delete itemsCopy[unconfirmedKey]
          },
        )

        if (unconfirmedItems !== UNKNOWN_KEYS)
          ($atom as any as ItemAtom).setItems(Object.entries(unconfirmedItems))
        ;($atom.$confirmed as any as ItemAtom).setItems(Object.entries(items))
      }
    }
  }
}

export function hostComputed<T>(compute: (watch: Watcher) => T): Atom<T | false> {
  return computed(($) => $($isHost) && compute($))
}

export const live = new LiveContext()
export const {$isHost, $hostId, $leader, $playerIds, pessimisticBatch} = live
export const myId = getOrSetItem(sessionStorage, "livestate:myId", () => nanoid(8))

// For debugging
;(window as any)._live = live
