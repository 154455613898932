import {useEffect, useRef, useState} from "react"
import {GeneratedImage, rpc} from "./client"
import {SubmissionCore} from "./components/Submission"
import {twRootLayout} from "./components/styles"
import {PromiseWithStatus} from "./utils/PromiseWithStatus"

type SubmissionItem = {
  text: string
  image: PromiseWithStatus<GeneratedImage>
}

export function Playground() {
  const [items, setItems] = useState<SubmissionItem[]>([])
  const textRef = useRef<HTMLTextAreaElement>(null)
  const scrollerRef = useRef<HTMLDivElement>(null)
  function submit() {
    const text = textRef.current!.value.trim()
    if (text === "") return
    textRef.current!.value = ""
    const item: SubmissionItem = {
      text,
      image: PromiseWithStatus.resolve(
        rpc.generateImage.mutate({
          prompt: text,
          isPromptGenerated: false,
          model: "flux/schnell",
          size: "square",
        }),
      ),
    }
    setItems([...items, item])
  }
  useEffect(() => {
    const scroller = scrollerRef.current!
    scroller.scrollTop = scroller.scrollHeight
  }, [items])

  return (
    <div className="absolute inset-0">
      <div className="flex flex-col h-full">
        <div ref={scrollerRef} className="flex-grow overflow-y-scroll flex flex-col items-center">
          <div className={twRootLayout}>
            <div className="pb-px">
              {items.map((item, i) => (
                <SubmissionCore
                  key={i}
                  text={item.text}
                  image={item.image}
                  onClick={() => {
                    if (item.image.status === "fulfilled") {
                      window.open(item.image.value.url, "_blank")
                    }
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="pb-2 px-2 w-full">
          {/* Wrapper needed for rounded corners otherwise scrollbar cuts them off */}
          <div className="border border-gray-300 rounded-xl bg-white overflow-hidden h-10">
            <textarea
              autoFocus
              ref={textRef}
              className="px-3 py-1.5 outline-none resize-none bg-transparent w-full h-full border-0"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault()
                  submit()
                  if (scrollerRef.current!.clientHeight < 650) {
                    textRef.current!.blur()
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
