import {useMemo} from "react"
import {randomInteger} from "remeda"
import {hostComputed, pessimisticBatch} from "../../livestate/liveContext"
import {useAtom, useWhen} from "../../xignal/react"
import {timeoutAtom} from "../../xignal/timeoutAtom"
import {$round, ScreenName, ScreenProps, setScreen} from "../game"
import {InterstitialName, InterstitialProps, interstitials} from "./interstitials"

const NUM_IMAGES_BY_ROUND = [4, 4, 4, 3]

type InterstitialScreenProps<T extends ScreenName> = {
  nextScreenName: T
  nextScreenProps: ScreenProps<T>
  name: InterstitialName
}
export function InterstitialScreen<T extends ScreenName>({
  nextScreenName,
  nextScreenProps,
  name,
}: InterstitialScreenProps<T>) {
  const round = useAtom($round)
  const {title, content, duration = 2500} = interstitials[name] as InterstitialProps
  const $timeout = useMemo(() => timeoutAtom(duration), [duration])
  const imageId = randomInteger(1, NUM_IMAGES_BY_ROUND[round]!)

  useWhen(
    useMemo(() => hostComputed(($) => $($timeout)), [$timeout]),
    () => {
      pessimisticBatch(() => setScreen(nextScreenName, nextScreenProps))
    },
  )

  return (
    <div
      className="bg-black flex flex-col items-center justify-center absolute inset-0 text-white gap-2 text-center"
      onClick={() => $timeout.set(true)}
    >
      {name.startsWith("round") ? (
        <img className="w-80 h-80" src={`/rounds/${round + 1}-${imageId}.webp`} />
      ) : (
        <h1 className="text-4xl">{title}</h1>
      )}
      <div className="text-xl">{content}</div>
    </div>
  )
}
