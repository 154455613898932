import {ordinalName} from "../../shared/utils/builtins"
import {HostButton, HostContinueButton} from "../components/components"
import {twScreenTitle} from "../components/styles"
import {$rematchNum, $round, $scores, finalRound, startGame, startNextRound} from "../game"

import {useMemo} from "react"
import Confetti from "react-confetti"
import {$names} from "../../livestate/playerAtoms"
import {batch} from "../../xignal/batch"
import {useAtom} from "../../xignal/react"
import {AvatarIcon} from "../AvatarIcon"

export function ScoreScreen(props: {}) {
  const round = useAtom($round)
  const scores = useAtom($scores)
  const sortedScores = useMemo(() => Object.entries(scores).sort((a, b) => b[1] - a[1]), [scores])
  const twTh = "text-left"
  const twPlace = "w-16"
  const gameOver = round === finalRound
  const winnerId = sortedScores[0]![0]
  return (
    <div>
      {gameOver ? (
        <div>
          <Confetti recycle={false} numberOfPieces={500} />
          <div className="text-center mb-5">
            <div className="mb-1 font-bold text-lg">Winner!</div>
            <AvatarIcon size={64} playerId={winnerId} />
            <div className="font-medium italic">{$names.getItem(winnerId)}</div>
          </div>
        </div>
      ) : (
        <div className={twScreenTitle}>{gameOver ? "Final Scores" : "Scoreboard"}</div>
      )}
      <table className="w-full mb-5">
        <thead>
          <tr>
            <th className={twTh}>Place</th>
            <th className={twTh}>Player</th>
            <th className={twTh}>Score</th>
          </tr>
        </thead>
        <tbody>
          {sortedScores.map(([playerId, score], index) => {
            return (
              <tr key={playerId}>
                <td className={twPlace}>{ordinalName(index + 1)}</td>
                <td className="flex items-center gap-1">
                  <AvatarIcon size={24} playerId={playerId} />
                  <div>{$names.getItem(playerId)}</div>
                </td>
                <td>{score}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {!gameOver ? (
        <HostContinueButton onClick={() => startNextRound()} />
      ) : (
        <div>
          <HostButton
            label="Play Again (same players)"
            onClick={() => {
              console.log("Playing again (same players)")
              batch(() => {
                $scores.set({})
                $round.set(-1)
                $rematchNum.set($rematchNum.get() + 1)
                startGame()
              })
            }}
          />
          <HostButton
            label="Exit to home screen"
            onClick={() => {
              location.href = "/"
            }}
          />
        </div>
      )}
    </div>
  )
}
