import {computed} from "../xignal/computed"
import {liveSetAtom, playerAtom} from "./liveAtom"
import {$playerIds, PlayerId} from "./liveContext"

export const $names = playerAtom<string>("names")
export const $botIds = liveSetAtom<PlayerId>("botIds")
export const $playerAndBotIds = computed(($) => {
  console.log("computing")
  const playerIds = $($playerIds)
  const botIds = $($botIds)
  const bothIds = new Set(playerIds)
  for (const botId of botIds) {
    bothIds.add(botId)
  }
  return bothIds
})
export const $avatars = playerAtom<number>("avatars")

export function avatarUrl(avatarId: number) {
  return `/characters/${avatarId}.jpeg`
}
