import {useMemo} from "react"
import {playerAtom, roundGrouped} from "../../livestate/liveAtom"
import {$playerIds, PlayerId, hostComputed, myId} from "../../livestate/liveContext"
import {$botIds, $names} from "../../livestate/playerAtoms"
import {getOrSetVal, hasAll} from "../../shared/utils/builtins"
import {useAtom, useWhen} from "../../xignal/react"
import {AvatarIcon} from "../AvatarIcon"
import {rpc} from "../client"
import {Submission} from "../components/Submission"
import {WaitingForOthers} from "../components/components"
import {twScreenTitle} from "../components/styles"
import {$gameId, $images, $theme, setScreen} from "../game"
import {cn} from "../utils/css"

const $votes = roundGrouped(playerAtom<PlayerId>("vote"))

export type VoteScreenProps = {submittedIds: PlayerId[]}
export function VoteScreen({submittedIds}: VoteScreenProps) {
  const myVote = useAtom($votes.$mine)
  const theme = useAtom($theme)

  useWhen(
    useMemo(() => hostComputed(($) => hasAll($($votes), $($playerIds))), []),
    () => {
      const voterIdsByVotee = new Map<PlayerId, PlayerId[]>()
      for (const [voterId, voteeId] of $votes.get()) {
        getOrSetVal(voterIdsByVotee, voteeId, []).push(voterId)
      }
      const voteResults = submittedIds.map((playerId) => ({
        playerId,
        voterIds: voterIdsByVotee.get(playerId) ?? [],
      }))
      voteResults.sort((a, b) => b.voterIds.length - a.voterIds.length)

      const gameId = $gameId.get()
      if (gameId) {
        const imageVoteCounts: Record<string, number> = Object.create(null)
        for (const playerId of submittedIds) {
          const imageResult = $images.getItem(playerId)
          if (imageResult && "id" in imageResult) {
            const numVotes = voterIdsByVotee.get(playerId)?.length ?? 0
            // No need to record anything for a bot w/ 0 votes (but for humans we do, see below)
            if ($botIds.has(playerId) && numVotes === 0) continue
            // We add 1 here to account for the player "voting" for their own image when they
            // chose among the multiple preview images in WriteScreen.
            imageVoteCounts[imageResult.id] = numVotes + 1
          }
        }
        void rpc.vote.mutate({gameId, votes: imageVoteCounts})
      }

      setScreen("VoteResultsScreen", {voteResults})
    },
  )

  if (myVote) {
    return (
      <div>
        <div className="text-center mt-3 mb-10">
          <div className="mb-2">You voted for</div>
          <AvatarIcon size={64} playerId={myVote} />
          <div className="font-medium italic">{$names.getItem(myVote)}</div>
        </div>
        <WaitingForOthers />
      </div>
    )
  }

  const shouldFilterMyId = submittedIds.length > 2
  return (
    <div>
      <div className={cn(twScreenTitle, "italic")}>{theme}</div>
      {submittedIds.map((playerId) => {
        if (shouldFilterMyId && playerId === myId) return null
        return (
          <Submission
            key={playerId}
            playerId={playerId}
            onClick={() => {
              $votes.setItem(myId, playerId)
            }}
          />
        )
      })}
    </div>
  )
}
