import * as Sentry from "@sentry/react"
import {nanoid} from "nanoid"
import * as R from "remeda"
import {liveAtom, liveSetAtom, playerAtom, roundAtoms, roundGrouped} from "../livestate/liveAtom"
import {$playerIds, PlayerId} from "../livestate/liveContext"
import {$botIds, $names} from "../livestate/playerAtoms"
import {getOrSet} from "../shared/utils/builtins"
import {batch} from "../xignal/batch"
import {ScreenName, ScreenProps} from "./App"
import {GeneratedImage, RpcInput, TemplateAndCompletion, fillPromptTemplate, rpc} from "./client"
import {numRounds, setInterstitial} from "./screens/interstitials"
import {PromiseWithStatus} from "./utils/PromiseWithStatus"
export type {ScreenName, ScreenProps}

export type SubmissionInput = {text: string}
export type ImageResult = GeneratedImage | {error: string}
export type Scores = Record<string, number>

export const finalRound = numRounds - 1

// <Atoms>
const $pastThemeChoosers = liveSetAtom<string>("pastThemeChoosers")
export const $pastThemes = liveAtom<string[]>("pastThemes", [])
export const $screen = liveAtom<[ScreenName, ScreenProps<ScreenName>]>("screen", ["Lobby", {}])
export const $round = liveAtom("round", -1)
export const $gameId = liveAtom<string>("gameId")
export const $rematchNum = liveAtom("rematchNum", 0)
export const $scores = liveAtom<Scores>("scores", {})
export const $theme = roundGrouped(liveAtom("theme", ""))
export const $submissions = roundGrouped(playerAtom<SubmissionInput>("submission"))
export const $images = roundGrouped(playerAtom<ImageResult>("image"))
// </Atoms>
export const loadedImageUrls = new Map<string, PromiseWithStatus<string>>()

$round.onChange((round) => {
  Sentry.setTag("round", round)
})

// Preload images
$images.onItemChange((_key, image) => {
  if (image && "url" in image) void loadImage(image.url)
})

export function loadImage(url: string) {
  return getOrSet(loadedImageUrls, url, () => {
    const img = new Image()
    return new PromiseWithStatus<string>((resolve, reject) => {
      img.onload = () => {
        resolve(url)
      }
      img.onerror = () => {
        reject(Error("Failed to load image"))
      }
      img.src = url
    }).ignoreUnhandledRejection()
  })
}

export function startGame() {
  console.log("Initializing round 0")
  batch(() => {
    const gameId = nanoid(12)
    $gameId.set(gameId)
    startNextRound()
    trackGameStart(gameId)
  })
}

export function trackGameStart(gameId: string) {
  void rpc.startGame.mutate({
    gameId: gameId,
    numBots: $botIds.size,
    numHumans: $playerIds.size,
    rematchNum: $rematchNum.get(),
  })
}

export function startNextRound() {
  const round = $round.get() + 1
  batch(() => {
    $round.set(round)
    const pastThemeChoosers = $pastThemeChoosers.get()
    const playerIds = [...$playerIds.get()]
    let pool = playerIds.filter((id) => !pastThemeChoosers.has(id))
    if (pool.length === 0) {
      $pastThemeChoosers.reset()
      pool = playerIds
    }
    const themeChooser = R.sample(pool, 1)[0]!
    $pastThemeChoosers.add(themeChooser)

    roundAtoms.resetAll()
    setInterstitial(("round" + round) as "round0", "ThemeScreen", {
      themeChooser,
    })
  })
}

export function setScreen<T extends ScreenName>(name: T, props: ScreenProps<T>) {
  $screen.set([name, props])
}

export function isDevMode() {
  return !!localStorage.getItem("devMode")
}
export function randomSeed() {
  return Math.floor(Math.random() * 4294967296)
}

export function submitPrompt(
  playerId: PlayerId,
  text: string,
  imagePromise: Promise<GeneratedImage>,
) {
  $submissions.setItem(playerId, {text})
  imagePromise.then(
    (image): void => {
      $images.setItem(playerId, image)
    },
    (err: unknown) => {
      $images.setItem(playerId, {error: `${err}`})
    },
  )
}

export function noResponseText(playerId: PlayerId) {
  return `[${$names.getItem(playerId)} did not respond]`
}

type GenerateImageForGameProps = {isPromptGenerated: boolean; round: number; playerName: string}

export async function generateImageForGameWithTemplate(
  props: TemplateAndCompletion &
    Omit<RpcInput["generateImage"], "prompt" | "template"> &
    GenerateImageForGameProps,
): Promise<GeneratedImage> {
  const {prompt} = fillPromptTemplate(props)
  return generateImageForGame({...props, prompt, template: props.template})
}

export async function generateImageForGame(
  props: RpcInput["generateImage"] & GenerateImageForGameProps,
): Promise<GeneratedImage> {
  return await rpc.generateImage.mutate({
    ...props,
    gameId: $gameId.get(),
    gameRound: props.round,
  })
}
