import {count, map, take} from "itertools"
import {useRef} from "react"
import {sample, shuffle} from "remeda"
import {$hostId, $isHost, live, myId} from "../../livestate/liveContext"
import {$avatars, $botIds, $names, $playerAndBotIds, avatarUrl} from "../../livestate/playerAtoms"
import {useAtom} from "../../xignal/react"
import addBotImage from "../assets/add-bot.png"
import {Avatar, AvatarWithName} from "../AvatarIcon"
import {HostButton} from "../components/components"
import {twButton} from "../components/styles"
import {startGame} from "../game"

const AVATAR_IDS = shuffle(take(39, count(1)))

function RoomCode() {
  return (
    <div className="flex justify-center text-center mb-1.5">
      <div className="border border-black px-2 py-0.5">
        <div className="mb-[-6px] text-sm">Room Code</div>
        <div className="text-3xl leading-tight">{live.roomCode}</div>
      </div>
    </div>
  )
}

export function AvatarScreen() {
  return (
    <div>
      <RoomCode />
      <div className="text-lg pb-[10px] text-center">Choose your avatar</div>
      {/* Using px here and below because the grid spacing shouldn't depend on the font size */}
      <div className="ml-[8px]">
        {AVATAR_IDS.map((avatarId) => (
          <div key={avatarId} className="inline-block">
            <img
              className="mr-[12px] mb-[5px] border border-yellow-900 rounded-md shadow-sm shadow-black cursor-pointer"
              src={avatarUrl(avatarId)}
              width="112"
              height="112"
              onClick={() => {
                $avatars.setItem(myId, avatarId)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export function NameScreen() {
  const nameRef = useRef<HTMLInputElement>(null)
  return (
    <div>
      <RoomCode />
      <form
        action="#" // Needed for correct "Go" label on iPhone keyboard
        onSubmit={(event) => {
          event.preventDefault()
          const name = nameRef.current!.value
          if (!name.trim()) {
            alert("Name can't be blank")
            return
          }
          $names.setItem(myId, name)
        }}
      >
        <label className="text-lg pl-1">Name</label>
        <input
          className="w-full rounded-lg border border-yellow-800 outline-none py-2 px-3 text-lg mb-2 mt-0.5"
          ref={nameRef}
        />
        <button className={twButton}>Continue</button>
      </form>
    </div>
  )
}

const botNames = [
  "Botty McBotFace",
  "Botty McBotterson",
  "Sir Bots A Lot",
  "Baby Got Bot",
  "Robot Deniro",
  "Botty Kennedy",
  "Botty Brown",
  "Botty Fischer",
  "Bot Dylan",
  "Bot Ulism",
  "Harry Botter",
  "Peanut Botter",
]

export function Lobby() {
  const playerIds = useAtom($playerAndBotIds)
  const hostId = useAtom($hostId)
  const hostName = useAtom($names.$item(hostId)) || "the host"
  const isHost = useAtom($isHost)

  return (
    <div>
      <RoomCode />
      <div className="text-lg pb-[10px] text-center italic">
        Waiting for {hostName} to start the game
      </div>
      <div className="ml-[8px] mb-2">
        {map(playerIds, (playerId) => (
          <AvatarWithName key={playerId} playerId={playerId} size={112} />
        ))}
        {isHost && (
          <Avatar
            key="AddBot"
            size={112}
            caption=""
            className="cursor-pointer"
            imageUrl={addBotImage}
            onClick={() => {
              const botId = "bot" + $botIds.size + 1
              const botName = window.prompt("Bot name:", sample(botNames, 1)[0])
              if (!botName) return
              $botIds.add(botId)
              $names.setItem(botId, botName)
              const unusedAvatarIds = new Set(AVATAR_IDS)
              for (const id of $avatars.get().values()) {
                unusedAvatarIds.delete(id)
              }
              const avatarId = sample([...unusedAvatarIds], 1)[0]!
              $avatars.setItem(botId, avatarId)
            }}
          />
        )}
      </div>
      <HostButton label="Let's go!" onClick={() => startGame()} />
    </div>
  )
}
