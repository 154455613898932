import {useMemo, useRef, useState} from "react"
import * as R from "remeda"
import {liveAtom, roundGrouped} from "../../livestate/liveAtom"
import {$isHost, hostComputed, myId} from "../../livestate/liveContext"
import {$botIds, $names, $playerAndBotIds} from "../../livestate/playerAtoms"
import {hasAll} from "../../shared/utils/builtins"
import {useAtom, useWhen} from "../../xignal/react"
import {generateBotSubmissions} from "../bot"
import {GeneratedImage, fillPromptTemplate} from "../client"
import {SubmissionCore} from "../components/Submission"
import {WaitingForOthers} from "../components/components"
import {twButton} from "../components/styles"
import {
  $round,
  $submissions,
  $theme,
  generateImageForGame,
  generateImageForGameWithTemplate,
  isDevMode,
  noResponseText,
  setScreen,
  submitPrompt,
} from "../game"
import {useOnce, useTimeLeft} from "../react/hooks"
import {cn} from "../utils/css"
import {setInterstitial} from "./interstitials"

const $timeLeft = roundGrouped(liveAtom("timeLeft", isDevMode() ? 9999 : 55))

type Preview = {
  text: string
  image: Promise<GeneratedImage>
  key: number
}

export function WriteScreen(props: {}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const round = useAtom($round)
  const theme = useAtom($theme)
  const submission = useAtom($submissions.$mine)
  const [previews, setPreviews] = useState<Preview[]>([])

  useOnce(() => {
    if ($isHost.get()) void generateBotSubmissions(round, theme)
  })

  useWhen(
    useMemo(() => hostComputed(($) => hasAll($($submissions), $($playerAndBotIds))), []),
    () => {
      setInterstitial("showcase", "ShowcaseScreen", {
        submittedIds: R.shuffle([...$submissions.get().keys()]),
      })
    },
  )

  const [timerStart] = useState(() => $timeLeft.get())
  const timeLeft = useTimeLeft(timerStart, 1000, (timeLeft) => {
    if ($isHost.get()) {
      // Used above to initialize the timer for late joiners
      $timeLeft.set(Math.round(timeLeft))
      if (timeLeft <= 0) {
        for (const botId of $botIds.get()) {
          if ($submissions.getItem(botId) == null) {
            // Must've been an error calling the API, or the original host dropped out before it
            // got the response.
            // TODO: submit "bot didn't respond" instead of kicking
            $botIds.delete(botId)
          }
        }
      }
    }
    if (timeLeft <= 0 && submission == null) {
      const text = previews[0]?.text || inputRef.current!.value.trim() || noResponseText(myId)
      const image =
        previews[0]?.image ??
        generateImageForGameWithTemplate({
          model: "flux/schnell",
          size: "square",
          completion: text,
          template: theme,
          isPromptGenerated: false,
          round,
          playerName: $names.getItem(myId)!,
        })
      submitPrompt(myId, text, image)
    }
  })

  const doPreviews = () => {
    const text = inputRef.current!.value.trim()
    if (text === "") return
    const {prompt} = fillPromptTemplate({completion: text, template: theme})
    const variants = [
      ["flux/schnell", "square"],
      ["flux/schnell", "square_hd"],
      ["fast-sdxl", "square_hd"],
    ] as const
    setPreviews(
      variants.map(([model, size], i) => ({
        text,
        image: generateImageForGame({
          model,
          size,
          prompt,
          template: theme,
          enhancePrompt: true,
          isPromptGenerated: false,
          round,
          playerName: $names.getItem(myId)!,
        }),
        key: i,
      })),
    )
  }

  return (
    <div className="text-center">
      <div className="font-bold pb-1">{Math.ceil(timeLeft)}</div>
      <div className="pb-2 text-lg italic">{theme}</div>
      {submission != null ? (
        <WaitingForOthers />
      ) : (
        <div>
          {previews.length === 0 ? (
            <form
              action="#" // Needed for correct "Go" label on iPhone keyboard
              onSubmit={(event) => {
                event.preventDefault()
                doPreviews()
              }}
            >
              <input
                ref={inputRef}
                className="w-full h-[46px] rounded-lg border border-yellow-800 outline-none py-2 px-3 text-lg mb-2"
              />
              <button className={twButton}>Submit</button>
            </form>
          ) : (
            <div>
              <div className="pb-3">
                <div>Tap your favorite image to submit it.</div>
              </div>
              {previews.map((preview) => (
                <SubmissionCore
                  key={preview.key}
                  text={preview.text}
                  image={preview.image}
                  onClick={() => {
                    submitPrompt(myId, preview.text, preview.image)
                  }}
                />
              ))}
            </div>
          )}
          {isDevMode() && (
            <button
              className={cn(twButton, "mt-1 mb-3")}
              onClick={() => {
                setScreen("ThemeScreen", {
                  themeChooser: myId,
                })
              }}
            >
              ThemeScreen
            </button>
          )}
        </div>
      )}
    </div>
  )
}
