import {useEffect, useState} from "react"
import {useEffectEvent} from "../src/react/hooks"
import {Atom} from "./atom"
import {effect} from "./computed"

export function useAtom<T>($atom: Atom<T>) {
  const [state, setState] = useState(() => $atom.get())
  useEffect(() => {
    return $atom.onChange((value) => {
      setState(value)
    })
  }, [$atom])

  return state
}

export function useWhen($condition: Atom<unknown>, event: () => void) {
  const effectEvent = useEffectEvent(event)
  useEffect(() => {
    return effect(($) => {
      if ($($condition)) effectEvent()
    })
  }, [$condition, effectEvent])
}
