import {Writable} from "type-fest"
import {getOrSet} from "../shared/utils/builtins"
import {Atom, WritableAtom, atom} from "../xignal/atom"
import {UNKNOWN_KEYS, batch} from "../xignal/batch"
import {WritableMapAtom, mapAtom} from "../xignal/mapAtom"
import {setAtom} from "../xignal/setAtom"
import {didHmr} from "./lib/hmr"
import {$isHost, $leader, ANY_PLAYER_LEADER, LiveContext, PlayerId, live, myId} from "./liveContext"
import {REMOVE_ITEM} from "./shared/msg"

// NB: LiveAtoms are always set optimistically. Values are updated locally immediately, before the
// server acknowledges them. This greatly simplifies everything and is the desired behavior in most
// cases anyway. If there's an edge case that requires waiting for the server to acknowledge an
// update, it could probably be done with a promise, or with a lastAcknowledged id.
//
// If an optimistically written update doesn't make it to the server, that means there's a
// connection issue and the socket should close at some point. When it reconnects, the latest
// server state will be sent down, and the client will update with that data.
//
// To avoid a situation where players think they are playing live while the socket it actually
// trying to reconnect, we could expose a reconnected event and clients could show a "reconnecting"
// lightbox (or similar).

export type Live<T extends Atom<any>> = T & {
  readonly liveKey: string
  readonly $confirmed: T
  _validateUpdate: (keys: unknown[] | UNKNOWN_KEYS) => void
}

export type LiveAtom<T = unknown> = Live<WritableAtom<T>>

export function livify<T extends WritableAtom<any>>(
  liveKey: string,
  atomCreator: () => T,
  serializer: (value: ReturnType<T["get"]>, keys: string[] | UNKNOWN_KEYS) => unknown,
  ctx = live,
): Live<T> {
  if (ctx._liveAtoms.has(liveKey) && !didHmr) {
    throw new Error(`Live atom with liveKey "${liveKey}" already exists`)
  }

  const $live = atomCreator() as Live<T>
  const $confirmed = atomCreator()
  ;($live as Writable<typeof $live>).$confirmed = $confirmed

  ctx._liveAtoms.set(liveKey, $live)
  ;($live.liveKey as Writable<typeof $live.liveKey>) = liveKey
  $live._validateUpdate = (keys) => {
    const leader = $leader.get()
    if (!$isHost.get() && leader !== ANY_PLAYER_LEADER && leader !== myId) {
      throw new Error(
        `Only the host or leader can update the atom. You can allow all players to make updates by calling $leader.set(ANY_PLAYER_LEADER)`,
      )
    }
  }

  const origSetWithKeys = $live._setWithKeys.bind($live)
  $live._setWithKeys = (newValue, keys) => {
    if (ctx._updateMode !== "local") {
      $live._validateUpdate(keys)
      const pendingUpdates = ctx._scheduleUpdate()
      const unconfirmedVersion = getOrSet(ctx._unconfirmedVersions, $live, () => new Map())
      if (keys === UNKNOWN_KEYS) {
        pendingUpdates.s[liveKey] = serializer(newValue, UNKNOWN_KEYS)
        delete pendingUpdates.i[liveKey]
        // UNKNOWN_KEYS supersedes any individual unconfirmed items with the same version
        for (const [key, version] of unconfirmedVersion) {
          if (version === ctx._stateVersion) unconfirmedVersion.delete(key)
        }
        unconfirmedVersion.set(UNKNOWN_KEYS, ctx._stateVersion)
      } else {
        if (liveKey in pendingUpdates.s) {
          pendingUpdates.s[liveKey] = serializer(newValue, UNKNOWN_KEYS)
        } else {
          const itemUpdates = (pendingUpdates.i[liveKey] ??= {})
          Object.assign(itemUpdates, serializer(newValue, keys as string[]))
          for (const key of keys) {
            unconfirmedVersion.set(key as string, ctx._stateVersion)
          }
        }
      }
    }
    if (ctx._updateMode !== "pessimistic") origSetWithKeys(newValue, keys)
  }

  return $live
}

export function liveAtom<T = undefined>(liveKey: string, ctx?: LiveContext): LiveAtom<T | undefined>
export function liveAtom<T>(liveKey: string, value: T, ctx?: LiveContext): LiveAtom<T>
export function liveAtom<T>(liveKey: string, value?: T, ctx = live): LiveAtom<T> {
  return livify(
    liveKey,
    () => atom(value as T),
    (value) => value,
    ctx,
  )
}

export function liveSetAtom<T = unknown>(liveKey: string, value?: Set<T>, ctx?: LiveContext) {
  return livify(
    liveKey,
    () => setAtom(value),
    (value, keys) => {
      const obj = {} as any
      const keysIter = keys === UNKNOWN_KEYS ? value.keys() : keys
      for (const key of keysIter) {
        obj[key] = value.has(key as any) ? true : REMOVE_ITEM
      }
      return obj
    },
    ctx,
  )
}

export function liveMapAtom<K = unknown, V = unknown>(
  liveKey: string,
  value?: Map<K, V>,
  ctx?: LiveContext,
) {
  return livify(
    liveKey,
    () => mapAtom(value),
    (value, keys) => {
      const obj = {} as any
      const keysIter = keys === UNKNOWN_KEYS ? value.keys() : keys
      for (const key of keysIter) {
        obj[key] = value.has(key as any) ? value.get(key as any) : REMOVE_ITEM
      }
      return obj
    },
    ctx,
  )
}

export type PlayerAtom<T = unknown> = Live<WritableMapAtom<PlayerId, T>> & {
  $mine: Atom<T | undefined>
}

export function playerAtom<T = undefined>(liveKey: string, ctx = live): PlayerAtom<T> {
  const $atom = liveMapAtom(liveKey, undefined, ctx) as PlayerAtom<T>
  const origValidateUpdate = $atom._validateUpdate
  $atom._validateUpdate = (keys) => {
    // Allow setting own key
    if (keys !== UNKNOWN_KEYS && keys.length === 1 && keys[0] === myId) return
    origValidateUpdate(keys)
  }
  // NB: Using a getter here so it's lazy
  Object.defineProperty($atom, "$mine", {
    get: () => {
      // $atom.$item is already memoized
      return $atom.$item(myId)
    },
  })
  return $atom
}

export function atomGroup() {
  const $atoms: WritableAtom[] = []
  return {
    add: <T extends WritableAtom<any>>($atom: T): T => {
      $atoms.push($atom)
      return $atom
    },
    resetAll: () => {
      batch(() => {
        for (const $atom of $atoms) $atom.reset()
      })
    },
  }
}

export const roundAtoms = atomGroup()
export const roundGrouped = roundAtoms.add
