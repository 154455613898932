export type Listener = (context: any) => void
export const UNKNOWN_KEYS = Symbol("UNKNOWN_KEYS")
export type UNKNOWN_KEYS = typeof UNKNOWN_KEYS

let isBatching = false
const batchQueue = new Map<Listener, Set<unknown> | UNKNOWN_KEYS>()

export function batch<T>(cb: () => T): T {
  if (isBatching) return cb()
  isBatching = true
  try {
    return cb()
  } finally {
    try {
      for (const [listener, keys] of batchQueue) {
        batchQueue.delete(listener)
        listener(keys)
      }
    } finally {
      batchQueue.clear()
      isBatching = false
    }
  }
}

export function addToBatch(listeners: Listener[], keys: unknown[] | UNKNOWN_KEYS) {
  for (const listener of listeners) {
    const prevKeys = batchQueue.get(listener)

    if (prevKeys === undefined) {
      batchQueue.set(listener, keys === UNKNOWN_KEYS ? UNKNOWN_KEYS : new Set(keys))
      continue
    }

    if (prevKeys === UNKNOWN_KEYS) continue

    if (keys === UNKNOWN_KEYS) {
      batchQueue.set(listener, UNKNOWN_KEYS)
    } else {
      for (const key of keys) prevKeys.add(key)
    }
  }
}

export function removeFromBatch(listener: Listener) {
  batchQueue.delete(listener)
}
