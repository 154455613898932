import {useNavigate, useParams} from "react-router-dom"
import {$avatars, $names} from "../livestate/playerAtoms"
import {useLiveRoom} from "../livestate/react"
import {useAtom} from "../xignal/react"
import {twButton, twRootLayout} from "./components/styles"
import {$screen} from "./game"
import {Loading} from "./Layout"
import {useResetLayout} from "./react/hooks"
import {InterstitialScreen} from "./screens/InterstitialScreen"
import {AvatarScreen, Lobby, NameScreen} from "./screens/Lobby"
import {ScoreScreen} from "./screens/ScoreScreen"
import {ShowcaseScreen} from "./screens/ShowcaseScreen"
import {ThemeScreen} from "./screens/ThemeScreen"
import {VoteResultsScreen} from "./screens/VoteResultsScreen"
import {VoteScreen} from "./screens/VoteScreen"
import {WriteScreen} from "./screens/WriteScreen"

export type ScreenName = keyof typeof screens
export type ScreenProps<T extends ScreenName> = Parameters<(typeof screens)[T]>[0]
// This should only be used in this file. Only the types above should need to be exported,
// otherwise you end up with circular dependencies that break HMR.
const screens = {
  Lobby,
  ThemeScreen,
  WriteScreen,
  ShowcaseScreen,
  VoteScreen,
  VoteResultsScreen,
  ScoreScreen,
  InterstitialScreen,
} as const

export function Play() {
  const {roomCode} = useParams() as {roomCode: string} // roomCode is required
  const navigate = useNavigate()
  const roomState = useLiveRoom(roomCode)

  if (roomState.status === "pending") return <Loading />
  if (roomState.status === "connected") return <Game />
  return (
    <div>
      <div className="text-lg py-2">{String(roomState.error)}</div>
      <button className={twButton} onClick={() => navigate("/")}>
        OK
      </button>
    </div>
  )
}

function Game() {
  const name = useAtom($names.$mine)
  const avatar = useAtom($avatars.$mine)
  const screenState = useAtom($screen)
  useResetLayout([screenState])

  const [screenName, screenProps] = screenState
  const Screen = screens[screenName]

  return (
    <div className={twRootLayout}>
      {name ? avatar ? <Screen {...(screenProps as any)} /> : <AvatarScreen /> : <NameScreen />}
    </div>
  )
}
