import {use, useState} from "react"
import {useNavigate} from "react-router-dom"
import {randomInteger} from "remeda"
import {live} from "../livestate/liveContext"
import {twButton, twRootLayout} from "./components/styles"
import {cn} from "./utils/css"

const LOGO_ID = randomInteger(1, 4)

if (location.hostname === "localhost" || location.hostname.startsWith("192.168.")) {
  live.setServerUrl(`${location.protocol}//${location.hostname}:8787`)
}

export function Start() {
  const [promise, setPromise] = useState<Promise<void>>()
  if (promise) use(promise)
  const navigate = useNavigate()
  const goToRoom = (roomCode: string) => navigate(`/play/${roomCode}`)

  return (
    <div className={twRootLayout}>
      <img className="w-full aspect-square" src={`/logos/${LOGO_ID}.webp`} />
      <div>
        <button
          className={cn(twButton, "mb-1.5")}
          onClick={(e) => {
            e.currentTarget.disabled = true
            setPromise(live.createRoom().then((roomCode) => goToRoom(roomCode)))
          }}
        >
          Start new game
        </button>
        <button
          className={twButton}
          onClick={() => {
            const roomCode = window.prompt("Enter the room code")?.trim()
            if (roomCode) goToRoom(roomCode.toUpperCase())
          }}
        >
          Join game
        </button>
      </div>
    </div>
  )
}
