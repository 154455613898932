import {makeFactory} from "../shared/utils/builtins"
import {Listener, UNKNOWN_KEYS, addToBatch, batch, removeFromBatch} from "./batch"
import {Unsubscribe, createEmitter} from "./events"

export let epoch = 0

export interface AtomEvents<T> {
  mount: () => void | (() => void)
}

export type Atom<T = unknown> = Omit<WritableAtom<T>, "set" | "reset">

export class WritableAtom<T = unknown> {
  protected value: T
  private initialValue: T
  private emitter = createEmitter<AtomEvents<T>>()
  private listeners: Listener[] = []
  private onUnmountCallbacks: (() => void)[] = []
  isEqual = Object.is

  constructor(value?: T) {
    this.value = value!
    this.initialValue = value!
  }

  private handleUnmount() {
    if (this.listeners.length > 0) return
    const callbacks = this.onUnmountCallbacks
    // Reset first so it's done even if a callback throws
    // (and to handle case of remounting during unmount callback).
    this.onUnmountCallbacks = []
    for (const cb of callbacks) cb()
  }

  private addListener(listener: Listener) {
    this.listeners.push(listener)
    return () => {
      removeFromBatch(listener)
      const index = this.listeners.indexOf(listener)
      if (index !== -1) {
        this.listeners.splice(index, 1)
        this.handleUnmount()
      }
    }
  }

  get(): T {
    return this.value
  }

  onChange(listener: (value: T, oldValue: T) => void): Unsubscribe {
    // Emit `mount` first so, e.g., the call to .get() below on a `computed`:
    // 1) can know to subscribe to its deps
    // 2) won't call the change listener on the initial computation
    if (this.listeners.length === 0) this.emitter.emit("mount")
    let oldValue = this.get()
    return this.addListener((context) => {
      const newValue = this.get()
      if (this.isEqual(oldValue, newValue)) return
      const currentOldValue = oldValue
      oldValue = newValue
      ;(listener as (value: T, oldValue: T, context: any) => void)(
        newValue,
        currentOldValue,
        context,
      )
    })
  }

  onMount(listener: () => void | (() => void)): Unsubscribe {
    return this.emitter.on("mount", () => {
      const onUnmount = listener()
      if (onUnmount) {
        this.onUnmountCallbacks.push(onUnmount)
      }
    })
  }

  _setWithKeys(newValue: T, keys: unknown[] | UNKNOWN_KEYS) {
    this.value = newValue
    epoch++
    batch(() => addToBatch(this.listeners, keys))
  }

  set(newValue: T) {
    if (!this.isEqual(this.value, newValue)) {
      this._setWithKeys(newValue, UNKNOWN_KEYS)
    }
  }

  reset() {
    this.set(this.initialValue)
  }
}

export const atom = makeFactory(WritableAtom) as {
  <T = undefined>(): WritableAtom<T | undefined>
  <T>(defaultValue: T): WritableAtom<T>
}
