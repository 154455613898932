import * as Sentry from "@sentry/react"
import React from "react"
import {createRoot} from "react-dom/client"
import {RouterProvider} from "react-router-dom"
import {myId} from "../livestate/liveContext"
import {$names} from "../livestate/playerAtoms"
import {$screen} from "./game"
import "./index.css"
import {router} from "./router.tsx"
import {ErrorScreen} from "./screens/ErrorScreen.tsx"

console.log("meta.env", import.meta.env)

Sentry.init({
  dsn: "https://a4f4a3067d0c98d88e0d4989578f1b03@o4506538978639872.ingest.sentry.io/4506539068489728",
  integrations: [],
  enabled: !import.meta.env.DEV,
  environment: import.meta.env.MODE,
  beforeSend(event) {
    // This can be undefined even though the types say otherwise
    const screenState = $screen.get()
    const screenName = screenState?.[0]
    event.tags ??= {}
    Object.assign(event.tags, {
      screen: screenName,
      // Intentionally setting these separate from Sentry's `user` tag, because these are ephemeral.
      "player.name": $names.getItem(myId),
      "player.id": myId,
    })
    return event
  },
})

const root = createRoot(document.getElementById("root")!)
root.render(
  <React.StrictMode>
    {/* Errors should rarely reach this since react-router has its own boundary, but it's here as a last backstop */}
    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
