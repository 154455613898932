import {REMOVE_ITEM} from "../livestate/shared/msg"
import {UNKNOWN_KEYS} from "./batch"

export interface HasKeys<K> {
  keys: () => Iterable<K>
  has: (key: K) => boolean
}

export interface ItemAtom<K = PropertyKey, V = unknown> extends HasKeys<K> {
  setItems: (items: Iterable<[K, V | typeof REMOVE_ITEM]>) => void
  setToItems: (items: Iterable<[K, V]>) => void
  getItem: (key: K) => V | undefined
}

export function processOnChangeItems<K>(
  newValue: HasKeys<K>,
  oldValue: HasKeys<K>,
  changedKeys: K[] | UNKNOWN_KEYS,
  processKey: (key: K) => void,
) {
  if (changedKeys !== UNKNOWN_KEYS) {
    for (const key of changedKeys) {
      processKey(key)
    }
    return
  }

  for (const oldKey of oldValue.keys()) {
    processKey(oldKey)
  }
  for (const newKey of newValue.keys()) {
    if (!oldValue.has(newKey)) {
      processKey(newKey)
    }
  }
}
