import {map} from "itertools"
import {REMOVE_ITEM} from "../livestate/shared/msg"
import {makeFactory} from "../shared/utils/builtins"
import {WritableAtom} from "./atom"
import {UNKNOWN_KEYS} from "./batch"
import {Unsubscribe} from "./events"
import {ItemAtom, processOnChangeItems} from "./itemAtom"

export type SetAtom<T = unknown> = Omit<WritableSetAtom<T>, "add" | "delete">

export class WritableSetAtom<T = unknown>
  extends WritableAtom<ReadonlySet<T>>
  implements ItemAtom<T, true>
{
  constructor(value?: Set<T>) {
    super(value ?? new Set())
  }

  get size() {
    return this.value.size
  }

  has(item: T) {
    return this.value.has(item)
  }

  keys() {
    return this.value.keys()
  }

  getItem(item: T) {
    return this.value.has(item) ? true : undefined
  }

  setToItems(items: Iterable<[T, true]>) {
    this.set(new Set(map(items, (item) => item[0])))
  }

  setItems(items: Iterable<[T, true | typeof REMOVE_ITEM]>) {
    const newSet = new Set(this.value)
    const changedKeys = []
    for (const [key, value] of items) {
      if (newSet.has(key) === value) continue
      if (value === true) {
        newSet.add(key)
      } else if (value === REMOVE_ITEM) {
        newSet.delete(key)
      } else {
        throw new Error(`Unexpected value in setItems: ${value}`)
      }
      changedKeys.push(key)
    }
    if (changedKeys.length) {
      this._setWithKeys(newSet, changedKeys)
    }
  }

  add(item: T) {
    if (this.value.has(item)) return
    const newSet = new Set(this.value)
    newSet.add(item)
    this._setWithKeys(newSet, [item])
  }

  delete(item: T) {
    if (!this.value.has(item)) return false
    const newSet = new Set(this.value)
    newSet.delete(item)
    this._setWithKeys(newSet, [item])
    return true
  }

  override onChange(
    listener: (value: Set<T>, oldValue: Set<T>, keys: T[] | UNKNOWN_KEYS) => void,
  ): Unsubscribe {
    return super.onChange(listener as any)
  }

  onItemChange(listener: (item: T, isInSet: boolean) => void) {
    return this.onChange((newValue, oldValue, keys) => {
      processOnChangeItems(newValue, oldValue, keys, (item) => {
        const isInSet = newValue.has(item)
        const oldIsInSet = oldValue.has(item)
        if (isInSet !== oldIsInSet) {
          listener(item, isInSet)
        }
      })
    })
  }

  onItemAdd(listener: (item: T) => void) {
    return this.onItemChange((item, isInSet) => {
      if (isInSet) listener(item)
    })
  }

  onItemRemove(listener: (item: T) => void) {
    return this.onItemChange((item, isInSet) => {
      if (!isInSet) listener(item)
    })
  }
}

export const setAtom = makeFactory(WritableSetAtom)
