// Adapted from https://github.com/asadm/playroom-docs/blob/main/examples/drawinggame/src/AvatarBar/AvatarIcon.jsx
import {PlayerId} from "../livestate/liveContext"
import {$avatars, $names, avatarUrl} from "../livestate/playerAtoms"
import {useAtom} from "../xignal/react"
import repoDefaultImage from "./assets/question-mark.png"
import {cn} from "./utils/css"

interface AvatarBaseProps {
  size: number
  className?: string
  style?: Partial<CSSStyleDeclaration>
  onClick?: () => void
}

interface AvatarIconProps extends AvatarBaseProps {
  playerId: PlayerId
  defaultImage?: string
}
export function AvatarIcon({
  playerId,
  size,
  className = "",
  style,
  defaultImage,
  onClick,
}: AvatarIconProps) {
  style ??= {}
  style.width = `${size}px`
  style.height = `${size}px`

  const avatarId = useAtom($avatars.$item(playerId))
  if (avatarId) {
    console.log("ava url: ", avatarUrl(avatarId))
    style["backgroundImage"] = `url(${avatarUrl(avatarId)})`
    style["backgroundSize"] = "contain"
  } else {
    style["backgroundImage"] = `url(${defaultImage || repoDefaultImage})`
    style["backgroundSize"] = defaultImage ? "cover" : "contain"
  }
  return (
    <div className={cn("inline-block", className)} style={style as any} onClick={onClick}></div>
  )
}

interface AvatarProps extends AvatarBaseProps {
  caption: string
  imageUrl: string
}
export function Avatar({imageUrl, size, caption, className = "", style, onClick}: AvatarProps) {
  style ??= {}
  style.width = `${size}px`
  style.height = `${size}px`

  return (
    <div className={cn("inline-block", className)} onClick={onClick}>
      <div className="flex flex-col items-center mb-[8px] mr-[12px]">
        <img
          className="border border-yellow-900 rounded-md shadow-sm shadow-black"
          src={imageUrl}
          style={style as any}
        />
        <div style={{maxWidth: size}} className="truncate">
          {caption}
        </div>
      </div>
    </div>
  )
}

export function AvatarWithName({playerId, size, className = "", style, onClick}: AvatarIconProps) {
  const avatarId = useAtom($avatars.$item(playerId))
  const name = useAtom($names.$item(playerId)) ?? "???"
  const imageUrl = avatarId ? avatarUrl(avatarId) : repoDefaultImage

  return (
    <Avatar
      imageUrl={imageUrl}
      caption={name}
      size={size}
      className={className}
      style={style}
      onClick={onClick}
    />
  )
}
