import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react"

// See https://github.com/react-restart/hooks/blob/master/src/useCommittedRef.ts
// - Uses useEffect instead of useLayoutEffect
export function useCommittedRef<TValue>(value: TValue): MutableRefObject<TValue> {
  const ref = useRef(value)
  useLayoutEffect(() => {
    ref.current = value
  }, [value])
  return ref
}

// See:
// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
// https://github.com/Volune/use-event-callback/blob/master/src/index.ts
// https://github.com/facebook/react/issues/14099#issuecomment-440013892
// https://github.com/react-restart/hooks/blob/master/src/useEventCallback.ts
// https://stackoverflow.com/q/76335194/278488
type Fn<Args extends any[], Return> = (...args: Args) => Return
export function useEffectEvent<Args extends any[], Return>(fn: Fn<Args, Return>): Fn<Args, Return> {
  const ref = useCommittedRef(fn)
  return useCallback((...args: Args) => ref.current(...args), [ref])
}

// Adapted from https://github.com/react-restart/hooks/blob/master/src/useEventListener.ts
// - Fixes useEffect dependencies
// - Fixes type errors in call to addEventListener
type EventHandler<Target, EventName extends keyof DocumentEventMap> = (
  this: Target,
  ev: DocumentEventMap[EventName],
) => void
export function useEventListener<
  Target extends Element | Document | Window,
  EventName extends keyof DocumentEventMap,
>(
  target: Target,
  event: EventName,
  listener: EventHandler<Target, EventName>,
  capture: boolean | AddEventListenerOptions = false,
) {
  const handler = useEffectEvent(listener) as EventListener
  useEffect(() => {
    target.addEventListener(event, handler, capture)
    return () => target.removeEventListener(event, handler, capture)
  }, [target, event, handler, capture])
}

export function useEventListenerCapture<
  Target extends Element | Document | Window,
  EventName extends keyof DocumentEventMap,
>(target: Target, event: EventName, listener: EventHandler<Target, EventName>) {
  return useEventListener(target, event, listener, true)
}

// Useful in cases where you'd normally use flushSync() (e.g. scrolling into view after rendering)
// but you can't because the state change that causes the rerender happens asynchronously and inside
// a library that you don't control (e.g. atomWithObservable).
export function useAfterNextRender() {
  const [callback, setCallback] = useState<() => void>()
  useEffect(() => {
    callback?.()
  }, [callback])
  return (callback: () => void) => setCallback(() => callback)
}

export function useForceUpdate() {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0)
  return forceUpdate
}

export function useInterval(interval: number | null, onInterval: () => void) {
  const onIntervalRef = useCommittedRef(onInterval)
  useEffect(() => {
    if (interval == null) {
      return
    }
    const id = setInterval(() => onIntervalRef.current(), interval)
    return () => clearInterval(id)
  }, [interval, onIntervalRef])
}

export function useTimeout(delay: number | null, onTimeout: () => void) {
  const onTimeoutRef = useCommittedRef(onTimeout)
  useEffect(() => {
    if (delay == null) {
      return
    }
    const id = setTimeout(() => onTimeoutRef.current(), delay)
    return () => clearTimeout(id)
  }, [delay, onTimeoutRef])
}

export function useElapsed(interval: number, onInterval?: (elapsed: number) => void) {
  const forceUpdate = useForceUpdate()
  const [startTime] = useState(() => Date.now())
  const elapsed = (Date.now() - startTime) / 1000

  useInterval(interval, () => {
    forceUpdate()
    onInterval?.((Date.now() - startTime) / 1000)
  })

  return elapsed
}

export function useTimeLeft(
  initialTime: number,
  interval: number,
  onInterval?: (timeLeft: number) => void,
) {
  const elapsed = useElapsed(interval, (elapsed) => {
    onInterval?.(Math.max(0, initialTime - elapsed))
  })
  return Math.max(0, initialTime - elapsed)
}

// This is just an alias for useState (with a void return type), but the name is way more clear for
// this use case, (and this provides a place to document the pattern). See:
// https://dev.to/video/the-equivalent-of-componentwillmount-using-react-hooks-11em
// https://github.com/martinstark/useOnce/blob/main/src/index.ts
//
// The return type is intentionally void - if you care about the return value, you should just use
// useState.
//
// NB: this shouldn't be used with React versions < 18.3, where it will run twice in
// strict mode. That behavior changed here: https://github.com/facebook/react/pull/25583
export const useOnce = useState as (fn: () => void) => void

// Most of the time it's better to use useOnce instead of this -- React docs[0] recommend certain side effects like this inside the render function when you're just setting some state that will cause a rerender.
// But, there are some use cases:
// - If you're updating state outside of your own components, React will log a warning if you do it
// during the render.
// - In react < 18.3 useOnce will run twice in StrictMode.
// [0] https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
export function useEffectOnce(callback: () => void) {
  const used = useRef(false)
  useEffect(() => {
    if (!used.current) {
      used.current = true
      callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function useResetLayout(deps: unknown[] = []) {
  useLayoutEffect(() => {
    // Start at the top on a new page
    window.scrollTo(0, 0)
    // Clear the selection on a new page. This fixes spurious selections when, e.g. you quickly click twice on an element that causes a new page to render (the first click so the window gets the focus, the second click to actually register the click).
    window.getSelection()?.removeAllRanges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
